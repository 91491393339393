import React, { useState, useEffect } from "react";
import { Navigate as Redirect } from "react-router-dom";
import {
    Row, Col, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import sessionAuthManager from "../helpers/sessionAuthManager";
import userDataProvider from "../providers/userDataProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from "lodash";
import AuthenticationView from "../views/AuthenticationView";
import { toast } from 'react-toastify'

export default function Login() {
    // const [isLoggedIn, setIsLoggedIn] = useState(sessionAuthManager.isLoggedIn())
    const [authenticated, setAuthenticated] = useState(sessionAuthManager.isLoggedIn())
    const [loading, setLoading] = useState(false)
    const UP = userDataProvider()


    const submitLogin = (values) => {
        setLoading(true)
        UP.login(values)
            .then((resp) => {
                // console.log("[DevLog] Authenticated: ", resp.access_token)
                localStorage.setItem('access_token', resp.access_token)
                setAuthenticated(true)
            }, (e) => {
                console.log("Login error", e.response.data, e.response.status);
                toast.error(e.response.status == 500 ? "Internal Server down" : "Credential mismatch")
            }).finally(() => setLoading(false))
    }


    if (authenticated) {
        return <Redirect to={"/"} />
    }
    return (
        <AuthenticationView>
            <Row>
                <Col className={"text-center"}>
                    <h2>Login</h2>
                    <p className="text-muted">Login to your accounnt</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={submitLogin}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Field
                                        as={Input}
                                        name="email"
                                        placeholder={"Email"}
                                        invalid={
                                            touched.email && !_.isEmpty(errors.email)
                                        } />
                                    {
                                        touched.email && errors.email
                                            ? <FormFeedback valid={false}>{errors.email}</FormFeedback>
                                            : null
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Field
                                        as={Input}
                                        type="password"
                                        name="password"
                                        placeholder={"Password"}
                                        invalid={
                                            touched.password && !_.isEmpty(errors.password)
                                        } />
                                    {
                                        touched.password && errors.password
                                            ? <FormFeedback valid={false}>{errors.password}</FormFeedback>
                                            : null
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Button color="primary" type={"submit"} size="lg" block disabled={loading}>
                                        {
                                            loading && <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
                                        }
                                        Login
                                    </Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </AuthenticationView>
    )
}


const RequiredFieldMessage = "Required"

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email.").required(RequiredFieldMessage),
    password: Yup.string().required(RequiredFieldMessage)
});