
const sessionAuthManager = () => {

    let authenticated = false;

    if (localStorage.getItem('access_token')) {
        authenticated = true;
    }

    const setAuth = (status) => {
        authenticated = status; //token

        if (!authenticated) {
            let event = new Event("login-expired");
            document.body.dispatchEvent(event);
        }
    }

    const getAuth = () => {

        if (window.authenticated != undefined) {
            authenticated = window.authenticated
            delete window.authenticated
        }

        return authenticated
    }

    const isLoggedIn = () => {
        // console.log(authenticated);

        return authenticated
    }

    return {
        setAuth,
        getAuth,
        isLoggedIn
    }
}

export default sessionAuthManager()