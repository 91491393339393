import React, { useMemo } from "react";
import {
    Row, Col,
    Navbar,
    Button,
    // NavbarBrand,
    Nav,
    NavItem,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup
} from 'reactstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import userDataProvider from "../providers/userDataProvider";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from "react-toastify";
import Webhook from "./webhooks/Webhook";

const MySwal = withReactContent(Swal)


export default function Header() {
    const appName = useMemo(() => process.env.REACT_APP_NAME, [])
    const appURL = useMemo(() => process.env.REACT_APP_BASE_URL, [])
    const UP = userDataProvider()



    const handleURLs = (e) => {
        e.preventDefault()
        MySwal.fire({
            html: <Webhook handleCopy={handleCopy} appURL={appURL} />,
            showConfirmButton: false,
            width: '50%',
            // width: isMobile ? '100%' : '60%',
            background: "transparent",
            customClass: {
                content: "text-left"
            }
        })

    }
    const handleCopy = (text, name) => {
        window.copyText(text)
        toast.success(`${name} copied successfully`)
    }


    return (
        <Row>
            <Col size={"md"}>
                <Navbar color="light" light expand="md">
                    {/* <NavbarBrand href="#"> */}
                    <h3>
                        {appName}
                    </h3>
                    {/* <img src={logo} alt="no image" /> */}
                    {/* </NavbarBrand> */}
                    <Nav navbar>
                        <NavItem>

                            <NavLink to={"javscript:void()"} onClick={handleURLs}>
                                <FontAwesomeIcon icon="link" size="2x" />
                            </NavLink>


                        </NavItem>

                        <NavItem>
                            {/* <NavLink  to={"/client"} activeClassName="active"> */}
                            <NavLink to={"/client"} className={(navData) => (navData.isActive ? "active-style" : 'none')} >
                                <FontAwesomeIcon icon="user" size="2x" />
                                {/* Client */}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            {/* <NavLink to={"/logout"}  onClick={()=>UP.logout} className={(navData) => (navData.isActive ? "active-style" : 'none')} > */}
                            {/* <NavLink  to={"/logout"} onClick={()=>UP.logout} activeClassName="active"> */}
                            {/* <FontAwesomeIcon icon="arrow-right-from-bracket" size="2x" />
                            </NavLink> */}
                            <Button onClick={UP.logout} className="btn btn-link" style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: 0
                            }}>
                                <FontAwesomeIcon icon="arrow-right-from-bracket" size="2x" />
                            </Button>
                        </NavItem>

                    </Nav>
                </Navbar>
            </Col>
        </Row>
    )
}
