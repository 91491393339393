// import axios from 'axios';
import sessionAuthManager from "../helpers/sessionAuthManager";
import _ from 'lodash'
let axios = window.axios

export default () => {
    const apiUrl = "/admin"
    // console.log("url" ,process.env.REACT_APP_BASE_URL)//BASE_URL);
    const axiosClient = (method, url, params) => {

        let payload = {
            method: method,
            url: url,

        }
        if (!_.isEmpty(params)) {

            if (method == "GET") {
                payload.params = params
            } else {
                payload.data = params
            }
        }

        return axios(payload);
    }

    return {

        login: (params) => {
            const url = `${apiUrl}/login`;
            return axiosClient("POST", url, params)
                .then(({ data }) => {
                    if ('access_token' in data) {

                        sessionAuthManager.setAuth(true)
                    }
                    return data;
                });
        },



        logout: () => {

            localStorage.removeItem('access_token')
            sessionAuthManager.setAuth(false)
            window.location.reload();
        },

        get: () => {
            const url = `${apiUrl}`;
            // axios.defaults.headers({ "Authorization": localStorage.getItem('token') })
            return axios.get(url, { headers: { "Authorization": `${localStorage.getItem('access_token')}` } })
                .then(({ data }) => {
                    return data
                })
            // return axiosClient("GET", url, {})
            //     .then(({ data }) => {
            //         return data.user
            //     });
        },


        patch: (id) => {
            const url = `${apiUrl}/user/${id}`;
            return axiosClient("PATCH", url, "")
                .then(({ data }) => {
                    return data
                });
        },

        delete: (id) => {
            const url = `${apiUrl}/admin/user/${id}`;
            return axiosClient("DELETE", url, "")
                .then(({ data }) => {
                    return data
                });
        },

    };
};