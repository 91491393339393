import React from 'react'

import { Container, Row, Col } from "reactstrap";

export default function DashboardView(props) {
    return (
        <Container fluid>
            <Row>
                <Col>
                <React.Fragment>

                    {props.children}
                </React.Fragment>
                </Col>
            </Row>
        </Container>
    )
}
