import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Card, CardHeader, CardBody,
    FormGroup, Label, Input,
    Row, Col, FormFeedback, Badge
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DatePickerField from './DatePickerField'

import clientProvider from '../../providers/clientProvider';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SaveButton from '../../helpers/SaveButton';
import moment from 'moment';

export default function ClientForm(props) {
    const { fetchData } = props
    const format = window.format //'YYYY-MM-DD'
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const CP = clientProvider()
    const [phone, setPhone] = useState('')
    const [realPhone, setRealPhone] = useState('')
    const [editData, setEditData] = useState({})
    const [smsOn, setSmsOn] = useState(false)
    const [emailOn, setEmailOn] = useState(false)
    const country = 'il'


    useEffect(() => {
        if ('virtualNumber' in props) {
            setLoading(true)
            CP.get({ 'virtualNumber': props.virtualNumber })
                .then(resp => {
                    console.log("[Devlogs] client details", resp);
                    setEmailOn(resp.notify)
                    setSmsOn(resp.sms)
                    setEditData(resp)
                    if ('virtualNumber' in resp) {
                        setPhone(resp.virtualNumber)
                        setRealPhone(resp.realNumber)
                    }
                }).finally(() => setLoading(false))
        }
    }, [props])


    const handleClientForm = (data) => {
        data.virtualNumber = "+" + phone.replace(/[^\d]/g, '');
        data.notify = emailOn
        data.sms = smsOn
        // console.log("SSS", realPhone.replace(/[^\d]/g, '').trim());
        if (smsOn && realPhone.replace(/[^\d]/g, '').trim() != "") {
            data.realNumber = "+" + realPhone.replace(/[^\d]/g, '').trim();
        }


        // remove whitespaces from email
        data.email = data.email.replace(/\s/g, "")
        data.expDate = moment(data.expDate).format(format) + " 23:59:59:999"
        console.log("check client form data 2", data);
        // return
        setSubmitLoading(true)

        if ('virtualNumber' in props) {
            data.virtualNumber = props.virtualNumber
            updateClient(data)
        } else {
            createClient(data)
        }

    }


    const createClient = (data) => {
        CP.post(data)
            .then(resp => {
                console.log("[Devlogs] create client", resp);
                fetchData()
                toast.success("Client Created Successfully")
                Swal.close()
            }, (e) => {
                console.log("check create client error", e);

                let resp = e.response.data;
                console.log("check create client error", resp);
                if ('message' in resp) {

                    toast.error(resp.message)
                }

            }).finally(() => setSubmitLoading(false))
    }
    const updateClient = (data) => {
        CP.patch(data)
            .then(resp => {
                console.log("[Devlogs] update client", resp);
                fetchData()
                toast.success("Client Updated Successfully")
                Swal.close()
            }, (e) => {
                console.log("check update client error", e);
                let resp = e.response.data;
                console.log("check update client error", resp);

                if ('message' in resp) {

                    toast.error(resp.message)
                }

            }).finally(() => setSubmitLoading(false))
    }

    return (
        <Card style={{
            textAlign: "left"
        }}>
            <CardHeader>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <h4 >
                        {
                            'virtualNumber' in props
                                ? "Client Details"
                                : "Client Form"
                        }
                    </h4>
                </div>

            </CardHeader>
            <CardBody>
                {
                    loading
                        ? <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
                        : <Formik
                            initialValues={{
                                virtualNumber: _.isEmpty(editData) ? '' : editData.virtualNumber,
                                timeLimit: _.isEmpty(editData) ? "" : editData.timeLimit,
                                // timeSpent: _.isEmpty(editData) ? 0 : Number(editData.timeSpent).toFixed(2),
                                scriptService: !_.isEmpty(editData) ? editData.scriptService : false,
                                userName: !_.isEmpty(editData) ? editData.userName : "",
                                realNumber: !_.isEmpty(editData) ? editData.realNumber : "",
                                email: !_.isEmpty(editData) ? editData.email : "",
                                sms: !_.isEmpty(editData) ? editData.sms : false,
                                notify: !_.isEmpty(editData) ? editData.notify : false,
                                expDate: !_.isEmpty(editData) ? moment.utc(editData.expDate).format(format) : moment().add(7, 'days').format(format),

                            }}
                            validationSchema={ServiceFormSchema}
                            onSubmit={handleClientForm}
                        >
                            {({ errors, touched }) => (
                                <Form>

                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Field
                                            as={Input}
                                            name="userName"
                                            placeholder={"enter userName"}
                                            invalid={
                                                touched.userName && !_.isEmpty(errors.userName)
                                            } />
                                        {
                                            touched.userName && errors.userName
                                                ? <FormFeedback valid={false}>{errors.userName}</FormFeedback>
                                                : null
                                        }
                                    </FormGroup>
                                    {
                                        !('virtualNumber' in props) &&
                                        <FormGroup>
                                            <Label>Virtual Number</Label>
                                            <PhoneInput
                                                country={country}
                                                value={phone ? phone : ''}
                                                onChange={(ph) => setPhone(ph)}
                                                inputProps={{
                                                    // name: 'phone',
                                                    required: true,
                                                    autoFocus: true,

                                                }}
                                            />
                                            <Field
                                                as={Input}
                                                type={"hidden"}
                                                name="virtualNumber"
                                                value={phone}
                                                invalid={
                                                    touched.virtualNumber && !_.isEmpty(errors.virtualNumber)
                                                } />
                                            {
                                                touched.virtualNumber && errors.virtualNumber
                                                    ? <FormFeedback valid={false}>{errors.virtualNumber}</FormFeedback>
                                                    : null
                                            }
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label>Notification</Label>
                                        <div id={"notification"}>
                                            <FormGroup className="pl-4">
                                                <Label check >
                                                    <Field
                                                        as={Input}
                                                        type={"checkbox"}
                                                        name="sms"
                                                        checked={smsOn}
                                                        onChange={() => setSmsOn(!smsOn)}
                                                        className={"mr-2 " + (!('virtualNumber' in props) && "mt-0")}
                                                        invalid={
                                                            touched.sms && !_.isEmpty(errors.sms)
                                                        } />
                                                    {
                                                        touched.sms && errors.sms
                                                            ? <FormFeedback valid={false}>{errors.sms}</FormFeedback>
                                                            : null
                                                    }
                                                    <span className='text-center'>{" "}Enable SMS</span>
                                                </Label>
                                                {
                                                    smsOn
                                                        ? <PhoneInput
                                                            country={country}
                                                            value={realPhone ? realPhone : ''}
                                                            onChange={(ph) => setRealPhone(ph)}
                                                            inputProps={{
                                                                // name: 'phone',
                                                                required: true,
                                                                autoFocus: true,


                                                            }}
                                                        />
                                                        : null
                                                }

                                            </FormGroup>
                                            <FormGroup className="pl-4">
                                                <Label check >
                                                    <Field
                                                        as={Input}
                                                        type={"checkbox"}
                                                        name="notify"
                                                        checked={emailOn}
                                                        onChange={() => setEmailOn(!emailOn)}
                                                        className={"mr-2 " + (!('virtualNumber' in props) && "mt-0")}
                                                        // validate={(event) => Validators.customFieldLevelValidation(event, [Validators.required, Validators.email, Validators.aol])} 
                                                        invalid={
                                                            touched.notify && !_.isEmpty(errors.notify)
                                                        } />
                                                    {
                                                        touched.notify && errors.notify
                                                            ? <FormFeedback valid={false}>{errors.notify}</FormFeedback>
                                                            : null
                                                    }
                                                    <span className='text-center'>{" "}Enable Email</span>
                                                </Label>
                                                {
                                                    emailOn
                                                        ? <>
                                                            <Field
                                                                as={Input}
                                                                name="email"
                                                                placeholder={"Email"}
                                                                invalid={
                                                                    touched.email && !_.isEmpty(errors.email)
                                                                } />
                                                            {
                                                                touched.email && errors.email
                                                                    ? <FormFeedback valid={false}>{errors.email}</FormFeedback>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }

                                            </FormGroup>
                                        </div>
                                    </FormGroup>

                                    {/* <FormGroup>
                                        <Label>
                                            Notification SMS
                                        </Label>
                                        <PhoneInput
                                            country={country}
                                            value={realPhone ? realPhone : ''}
                                            onChange={(ph) => setRealPhone(ph)}
                                            inputProps={{
                                                // name: 'phone',
                                                required: true,
                                                autoFocus: true,


                                            }}
                                        />
                                        <Field
                                            as={Input}
                                            type={"hidden"}
                                            name="realNumber"
                                            value={realPhone}
                                            invalid={
                                                touched.realNumber && !_.isEmpty(errors.realNumber)
                                            } />
                                        {
                                            touched.realNumber && errors.realNumber
                                                ? <FormFeedback valid={false}>{errors.realNumber}</FormFeedback>
                                                : null
                                        }
                                    </FormGroup> */}
                                    <FormGroup>
                                        <Label>Time Limit (Min)</Label>

                                        <Field
                                            as={Input}
                                            type={"number"}
                                            name="timeLimit"
                                            invalid={
                                                touched.timeLimit && !_.isEmpty(errors.timeLimit)
                                            } />
                                        {
                                            touched.timeLimit && errors.timeLimit
                                                ? <FormFeedback valid={false}>{errors.timeLimit}</FormFeedback>
                                                : null
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Expiration Date</Label>

                                        <DatePickerField
                                            name="expDate"
                                            invalid={
                                                touched.expDate && !_.isEmpty(errors.expDate)
                                            }
                                        />
                                        {
                                            touched.expDate && errors.expDate
                                                ? <FormFeedback valid={false}>{errors.expDate}</FormFeedback>
                                                : null
                                        }
                                    </FormGroup>
                                    {/* {
                                        'virtualNumber' in props
                                            ? <FormGroup>
                                                <Label>Time Spent</Label>

                                                <Field
                                                    as={Input}
                                                    type={"number"}
                                                    name="timeSpent"
                                                    invalid={
                                                        touched.timeSpent && !_.isEmpty(errors.timeSpent)
                                                    } />
                                                {
                                                    touched.timeSpent && errors.timeSpent
                                                        ? <FormFeedback valid={false}>{errors.timeSpent}</FormFeedback>
                                                        : null
                                                }
                                            </FormGroup>
                                            : null
                                    } */}
                                    <FormGroup className="pl-4">
                                        <Label check >
                                            <Field
                                                as={Input}
                                                type={"checkbox"}
                                                name="scriptService"
                                                className={"mr-2 " + (!('virtualNumber' in props) && "mt-0")}
                                                invalid={
                                                    touched.scriptService && !_.isEmpty(errors.scriptService)
                                                } />
                                            {
                                                touched.scriptService && errors.scriptService
                                                    ? <FormFeedback valid={false}>{errors.scriptService}</FormFeedback>
                                                    : null
                                            }
                                            <span className='text-center'>{" "}Enable Transcript</span>
                                        </Label>

                                    </FormGroup>

                                    <SaveButton btnText={'Save'} loading={submitLoading} />

                                </Form>
                            )}
                        </Formik>
                }
            </CardBody>
        </Card>
    )
}

function invalidEmails(msg) {
    return this.test({
        name: "invalidEmails",
        message: msg,
        test: (value) => {
            let isValid = true
            if (value == undefined) {
                isValid = false
            } else {

                const emails = value.replace(/\s/g, "").split(",");
                emails.every((email) => {
                    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                    isValid = regex.test(email.toLowerCase());

                    if (!isValid) {

                        return false
                    }
                    return true

                });
            }

            return isValid
        },
    });
}

Yup.addMethod(Yup.string, "invalidEmails", invalidEmails);

const ServiceFormSchema = Yup.object().shape({
    userName: Yup
        .string()
        .min(4, "Atleast 4 charactor required")
        .required("Name required"),
    notify: Yup.boolean(),
    expDate: Yup.string().required("Please enter a valid date"),
    // expDate: Yup.date().nullable().required('Start Date is required')
    //     .min(new Date(), 'Start Date must be later than today'),
    // email: Yup.string().email("Please enter a valid email.").required("required"),
    email: Yup.string().when('notify', {
        is: true,
        then: Yup.string().invalidEmails("One or more email is not valid")
            .required("required"),
        otherwise: Yup.string(),
    }),
    timeLimit: Yup.number()
        .required("Number required (in minutes)"),
    scriptService: Yup.boolean()

});
