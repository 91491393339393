import React from 'react';
import ReactDOM from 'react-dom';
// import './sass/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fas)


ReactDOM.render(
  // <React.StrictMode>
  <React.Fragment>
    <App />
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} theme='colored' />
  </React.Fragment>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
