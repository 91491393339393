import React, { useMemo } from "react";
import {

    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Step1 from '../images/Step1.png'
import Step2 from '../images/Step2.png'
import video from '../images/Studio-Twilio.webm'


export default function Webhook(props) {
    const { handleCopy, appURL } = props
    const size = 400

    return (
        <Card style={{
            textAlign: "left"
        }}>
            <CardHeader className="d-inline">
                <h4>Webhook URLs</h4>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <video style={{
                        width: "100%"
                    }} controls >
                        <source src={video} type="video/mp4" />
                    </video>
                </FormGroup>
                <FormGroup>
                    <Label>Step 1</Label>
                    <p>
                        From the widgets add "HTTP Call" widget. Edit it, it will show options same as below image. Enter following Validity URL:
                        <span>
                            &nbsp; {`${appURL}/api/call/test`}
                            <FontAwesomeIcon
                                icon={"copy"}
                                className="mx-2 cursor-pointer"
                                title="copy to click"
                                onClick={() => handleCopy(`${appURL}/api/call/test`, "Validitiy URL")}
                            />
                        </span>
                    </p>
                    <div>
                        <img src={Step1} alt={"step1"} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>Step 2</Label>
                    <p>
                        Repeat Step 1 and add the widget at the end of each path. Copy following Call End URl:
                        <span>
                            &nbsp;{`${appURL}/api/call/end`}
                            <FontAwesomeIcon
                                icon={"copy"}
                                className="mx-2 cursor-pointer"
                                title="copy to click"
                                onClick={() => handleCopy(`${appURL}/api/call/end`, "Call End URL")}

                            />
                        </span>
                    </p>
                    <div>
                        <img src={Step2} alt={"step2"} />
                    </div>
                </FormGroup>
            </CardBody>
        </Card>
    )
}
