import React from 'react'
import {
    Card, CardHeader, CardBody, ListGroup, ListGroupItem,
} from 'reactstrap';

export default function CallRecords(props) {
    const { passage } = props


    return (
        <Card style={{
            textAlign: "left"
        }}>
            <CardHeader>
                <h4>Call Records</h4>
            </CardHeader>
            <CardBody>
                <div className='mb-3 ' style={{
                    textAlign: "end"
                }}>

                    <strong>
                        {/* : */}
                        תוכן ההודעה
                    </strong>
                    &nbsp;
                    {/* {getReverseString(passage)} */}
                    <ListGroup>
                        {
                            passage.split("||").map(item => {

                                return <ListGroupItem idx={item}>{item}</ListGroupItem>
                            }
                            )
                        }
                    </ListGroup>
                </div>
            </CardBody>
        </Card >
    )
}
