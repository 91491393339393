import React from 'react'
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SaveButton(props) {
    const { loading, customClass, btnText, myStyle, color } = props
    return (
        <Button
            color={"success"}
            type={"submit"}
            className={customClass ? customClass : "mb-3"}
            disabled={loading}
            style={myStyle ? myStyle : {
                float: 'right'
            }}
        >
            {
                loading && <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
            }
            {btnText}
        </Button>
    )
}

SaveButton.propTypes = {
    // You can declare that a prop is a specific JS type. By default, these
    // are all optional.
    loading: PropTypes.bool,
    btnText: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    myStyle: PropTypes.object
}