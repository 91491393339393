import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from "reactstrap";


const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);
  return (
    <Button id={"play-button"} onClick={toggle} color={playing ? "success" : "warning"}>
      {
        playing
          ? <FontAwesomeIcon icon={"pause"} />
          : <FontAwesomeIcon icon={"play"} />
      }
    </Button>
  );
};



const useAudio = url => {
  // const [audio] = useState(new Audio(url));
  const audio = useMemo(() => new Audio(url), []);

  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    let alreadyplaying = document.querySelector('#play-button.btn-success')
    if (alreadyplaying) {
      // console.log("alreadyplaying", alreadyplaying);
      alreadyplaying.click()
    }
    setPlaying(!playing);
  }


  useEffect(() => {

    playing ? audio.play() : audio.pause();

  }, [playing])


  useEffect(() => {

    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };

  }, []);

  return [playing, toggle];
};


export default Player;