import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import { toast } from 'react-toastify';

export default function RangeDropdown(props) {
  const { startDate, setStartDate } = props
  const handleDate = (date) => {

    if (props.setStartDate) {

      setStartDate(date)

    } else {
      toast.info(
        "Please first select custom range"
      )
    }
  }

  return (
    <DatePicker
      // size={"sm"}
      selected={startDate}
      onChange={handleDate}
      maxDate={moment().toDate()}
      className={"form-control custom-sm"}
      dateFormat={"dd-MM-yyyy"}
    />
  );
}
