import React from 'react'
import { Container, Row, Col } from 'reactstrap';

export default function AuthenticationView(props) {
    return (
        <div>
            <Container fluid>
                <Row className="auth-container d-flex justify-content-center align-items-center">
                    <Col sm={12} md={3}>
                        { props.children }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
