import React, { useEffect } from "react";
import { Button, Col, Container, Row, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import AuthenticationView from "../views/AuthenticationView";
// import { toast } from "react-toastify";


export default function Page404() {
    // useEffect(() => {
    //     toast.success("HI")
    // }, [])

    return (
        <AuthenticationView>

            <div>
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Oh no! You've found our junior developer's homepage.</h4>
                <p className=" float-left">Despite sleeping on the couch most of the day, our junior developer still finds time to do some coding...</p>
            </div>
            <FormGroup className={'text-center'}>
                <Button
                    className="text-uppercase float-left"
                    tag={Link}
                    to={"/login"}
                    color={'link'}
                >
                    <FontAwesomeIcon icon={'arrow-left'} transform="left-5 down-1" /> back to home
                </Button>
            </FormGroup>
        </AuthenticationView>
    );
}