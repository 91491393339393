import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NoData(props) {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(props.loading ? props.loading : false)
    }, [props])

    return (
        <div className="text-center my-5">
            {
                loading
                    ? <FontAwesomeIcon icon="spinner" spin />
                    : <>
                        <FontAwesomeIcon icon="exclamation-circle" size="2x" color="grey" />
                        <div >
                            {
                                props.message
                                    ? props.message
                                    : "You don't have any data to display!"
                            }
                        </div>
                    </>
            }
        </div>
    )
}