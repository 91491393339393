import sessionAuthManager from "../helpers/sessionAuthManager";
import _ from 'lodash'
let axios = window.axios


export default () => {
    const apiUrl = "/client"
    const axiosClient = (method, url, params) => {
        // axios.defaults.baseURL="http://twilio-tracker.alfaintelli.tech"
        axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`
        let payload = {
            method: method,
            url: url,

        }
        if (!_.isEmpty(params)) {

            if (method == "GET") {
                payload.params = params
            } else {
                payload.data = params
            }
        }

        return axios(payload);
    }

    return {

        get: (params) => {
            let url = `${apiUrl}`;
            if ('virtualNumber' in params) {
                url += `/${params.virtualNumber}`
            } else {
                url += `?numero=${params.page}`
            }
            return axiosClient("GET", url, {})
                .then(({ data }) => {
                    return data
                });
        },
        getCallLog: (params) => {
            let url = `${apiUrl}`;
            if ('virtualNumber' in params) {
                url += `/calls/${params.virtualNumber}`
                // delete params.page
                delete params.virtualNumber
            }
            return axiosClient("GET", url, params)
                .then(({ data }) => {
                    return data
                });
        },
        post: (params) => {
            let url = `${apiUrl}`;
            return axiosClient("POST", url, params)
                .then(({ data }) => {
                    return data
                });
        },


        patch: (params) => {
            let url = `${apiUrl}`;
            if ('virtualNumber' in params) {
                url += `/${params.virtualNumber}`
            }
            return axiosClient("PATCH", url, params)
                .then(({ data }) => {
                    return data
                });
        },

        delete: (id) => {
            const url = `${apiUrl}/admin/user/${id}`;
            return axiosClient("DELETE", url, "")
                .then(({ data }) => {
                    return data
                });
        },

    };
};