import React from "react";
import { Formik, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, validateField } = useFormikContext();
    // const { setFieldValue, setFieldTouched, validateField, values, handleBlur } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            // maxDate={moment().toDate()}
            minDate={moment().toDate()}
            className={"form-control"}
            dateFormat={"dd-MM-yyyy"}
            // onChangeRaw={e => {
            //     console.log("chgeck raw");
            //     setFieldTouched(field.name, true, true);
            // }}

            onChange={(val) => {
                console.log("chgeck onchange");

                setFieldValue(field.name, val);
            }}

        // onCalendarClose={val => {
        //     // Force-validate onCalendarClose to avoid any potential DatePicker Blur issues
        //     // Didn't help
        //     validateField(props.name);
        // }}
        // onBlur={e => {
        //     // Call Formik's handleBlur
        //     // Didn't help
        //     handleBlur(e);
        // }}

        />
    );
};

export default DatePickerField