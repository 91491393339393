import { Routes as Switch, Route, BrowserRouter, Navigate } from "react-router-dom";
import './helpers/bootstrap'
import Login from "./components/Login";
import Page404 from "./components/Page404";
import Home from "./authenticated/Home";

import Dashboard from './authenticated/Dashboard';
import Client from "./authenticated/client/Client";
import Details from "./authenticated/client/Details";
import userDataProvider from "./providers/userDataProvider";


function App() {
  const UP = userDataProvider()
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" element={<Login/>} />
        <Route path='/' element={<Home/>}>
            <Route path="client/:virtualNumber" element={<Details />} />
            <Route path="client" element={<Client/>}/>
            {/* <Route path="logout" element={<p>{UP.logout()}</p>} /> */}
            {/* <Route exact path="/" element={<Dashboard />} /> */}
            <Route path="/" element={<Navigate to={"/client"}/>} />
        </Route>
        <Route exact path="*" element={<Page404/>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
