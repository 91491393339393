import React, { useState, useEffect, useMemo } from 'react'

import { Row, Col, Card, CardHeader, CardBody, Button, Badge } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TableBody from '../../helpers/TableBody';

import { useNavigate } from 'react-router-dom';
import _ from "lodash";

import clientProvider from '../../providers/clientProvider';
import ClientForm from './ClientForm';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NoData from '../../helpers/NoData';
const MySwal = withReactContent(Swal)

// import { isMobile } from 'react-device-detect'

export default function Client() {
  const navigate = useNavigate();
  const CP = useMemo(() => clientProvider(), [])
  // const CP = clientProvider()
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(0)
  const [loading, setLoading] = useState(false)
  const [clientId, setClientId] = useState(null)
  // const [update, setUpdate] = useState(false)

  const [columns, setColumns] = useState([
    {
      dataField: "blocked",
      text: " ",
      formatter: (cell) => {
        return <div title={cell ? "Client is Blocked " : "Client is active"} className={"text-center"}>
          {
            <FontAwesomeIcon
              icon="circle"
              className={cell ? "text-danger" : "text-success"} />
          }
        </div>
      }

    },

    {
      dataField: "virtualNumber",
      text: "Twilio Number",
      sort: true,
      formatter: (cell) => <Button
        // tag={Link}
        color="link"
        onClick={(e) => {
          e.preventDefault();
          handleDetail(cell)
        }}>
        {cell}
      </Button>

    },
    {
      dataField: "userName",
      text: "Name",
      sort: true,
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    {
      dataField: "timeLimit",
      text: "Credits",
      sort: true,
      formatter: (cell) => {
        return <div>
          {
            window.getHMS(Number(cell) * 60)
          }
        </div>
      }
    },
    {
      dataField: "timeSpent",
      text: "Spent",
      sort: true,
      formatter: (cell) => {
        // console.log("call", cell);
        return <div>
          {
            Number(cell)
            //window.getHMS(Number(cell) * 60)
          } min
        </div>
      }

    },

    // {
    //   dataField: "realNumber",
    //   text: "Real Number",

    // },
    {
      dataField: "scriptService",
      text: "Transcription",
      formatter: (cell) => {
        return <Badge
          // title={cell ? "You use script service " : "You not use script service"}
          color={cell ? "success" : "danger"}>

          {
            cell ? "Yes" : "No"
          }
        </Badge>
      }

    },

    {
      dataField: "_id",
      text: "Logs ",
      formatter: (cell, row) => <div
        onClick={() => navigate(`/client/${row.virtualNumber}`)}
        className='cursor-pointer'>
        <FontAwesomeIcon icon={"arrow-right"} />
      </div>
    }

  ]);



  useEffect(() => {
    fetchData()
    return () => {
      setList([])
      setColumns([])
    }
  }, [])

  const fetchData = (page = 1) => {
    let params = {
      page: page

    }
    if (page === 'NaN') {
      // page = 1
      return
    }

    setLoading(true)
    CP.get(params)
      .then(resp => {
        // console.log("[DevLogs] client list response:", resp)
        setTotalSize(resp.total)
        setPage(resp.current)
        setSizePerPage(10)
        setList(resp.records)
      }).finally(() => setLoading(false))
  }

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    // console.log(type, page, sizePerPage)

    setPage(page)
    fetchData(page, sizePerPage, sortField, sortOrder);
  }

  const createClient = () => {
    MySwal.fire({
      html: <ClientForm fetchData={fetchData} />,
      showConfirmButton: false,
      // width: '60%',
      // width: isMobile ? '100%' : '60%',
      background: "transparent",
      customClass: {
        content: "text-left"
      }
    })
  }
  const handleDetail = (virtualNumber) => {
    setClientId(null)
    setClientId(virtualNumber)

  }


  return (
    <Row className='mt-3'>
      <Col md={7}>
        <Card>
          <CardHeader>
            <div className={"d-flex justify-content-between align-items-center"}>
              <h4>Clients</h4>
              <Button
                onClick={createClient}
                // size={"lg"}
                className={"float-right d-none d-md-block"}
                // block
                color="warning">
                <FontAwesomeIcon icon="plus" />
                {" "}
                Client
              </Button>
              <Button
                onClick={createClient}
                size={"sm"}
                className={"float-right d-block d-sm-none"}
                color="primary">
                <FontAwesomeIcon icon="plus" />
                {" "}
                Client
              </Button>
            </div>
          </CardHeader>
          <CardBody className={"text-left"}>
            {
              loading
                ? <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
                : <TableBody
                  rowId={"virtualNumber"}
                  list={list}
                  page={page}
                  sizePerPage={sizePerPage}
                  totalSize={totalSize}
                  handleTableChange={handleTableChange}
                  columns={columns}
                />
            }
          </CardBody>
        </Card>
      </Col>
      <Col md={5}>
        <div>
          {
            clientId
              ? <ClientForm fetchData={fetchData} virtualNumber={clientId} />
              : null
            //  <Card>
            //   <CardHeader>
            //     <h4>Client Details</h4>
            //   </CardHeader>
            //   <CardBody>
            //     <NoData message={"Please click on virtual number to see details of it"} />
            //   </CardBody>
            // </Card>
          }
        </div>
      </Col>
    </Row>
  )
}
