import axios from 'axios'
import "../sass/app.scss";

window.getHMS = (sec) => {

  if (sec <= 0) {
    return 0
  }
  // sec = Number(sec);
  var h = Math.floor(sec / 3600);
  var m = Math.floor(sec % 3600 / 60);
  var s = Math.floor(sec % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
  // var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + " min " : "";
  // var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
  var sDisplay = s > 0 ? s + " sec" : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

window.format = 'YYYY-MM-DD'

window.copyText = (text) => {
  let link = document.createElement("textarea");
  link.value = text
  document.body.appendChild(link);
  link.select();
  link.setSelectionRange(0, link.value.length);
  document.execCommand("copy");
  document.body.removeChild(link);
}


axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api"
if (localStorage.getItem('dev')) {

  console.log("env", process.env);
}
window.axios = axios;