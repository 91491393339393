import React, { useState, useEffect } from "react";
import { Route, Navigate as Redirect, useHref, Routes, Navigate, Outlet } from "react-router-dom";
import sessionAuthManager from "../helpers/sessionAuthManager";
import DashboardView from '../views/DashboardView'
import Header from "./Header";
import userDataProvider from "../providers/userDataProvider";

export default function Home() {
    const UP = userDataProvider()
    const [loginExpired, setLoginExpired] = useState(!sessionAuthManager.isLoggedIn())
    // useHref
    useEffect(() => {
        fetchUser()
        const element = document.body;
        element.addEventListener("login-expired", () => {
            setLoginExpired(true)
        }, false);

        return () => {
            element.removeEventListener("login-expired", () => { }, false);
        };

    }, [])

    const fetchUser = () => {
        UP.get().then(resp => {
            // console.log("[Devlogs] resp", resp);
        }).catch(e => {
            console.log("user not login now", Object.values(e.response.data)[0]);
            if (Object.values(e.response.data)[0]) {
                UP.logout()
            }
        })
    }


    if (loginExpired) {
        return <Redirect to="/login" />
    }

    return (
        <React.Fragment>
            <div className="text-right">
                <Header />
            </div>
            <DashboardView>
                <Outlet />
            </DashboardView>
        </React.Fragment>
    )
}
