import React, { useEffect, useState, useMemo } from 'react'
import {
    Button,
    Card, CardHeader, CardBody,
    FormGroup, Label, Input,
    Row, Col, Table, Badge
} from 'reactstrap';
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import clientProvider from '../../providers/clientProvider';
import { useNavigate as useHistory } from 'react-router-dom';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify';
import CallRecords from './CallRecords';
import TableBody from '../../helpers/TableBody';
import moment from 'moment';
import Player from "../Player";
import RangeDropdown from '../../helpers/RangeDropdown';
const MySwal = withReactContent(Swal)

export default function Details() {
    const { virtualNumber } = useParams()
    const backendURL = useMemo(() => process.env.REACT_APP_BASE_URL, [])

    const history = useHistory()
    const CP = clientProvider()
    const [client, setClient] = useState({})
    const [loading, setLoading] = useState(false)
    const [callLogLoading, setCallLogLoading] = useState(false)
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [totalSize, setTotalSize] = useState(0)

    const [startDate, setStartDate] = useState(
        moment().clone().subtract(1, "M").startOf('month').toDate() // Date Object
    );
    const [endDate, setEndDate] = useState(moment().toDate())
    const [isCustomDate, setIsCustomDate] = useState(true)

    const [columns, setColumns] = useState([
        {
            dataField: "callId",
            text: "Twilio ID"
        },
        {
            dataField: "callRecording",
            text: "Call Recording",
            formatter: (passage, row) => {
                return <div className={"display-inline"}>

                    <Player
                        url={`${backendURL}/api/${row.mash}`}
                    />
                    {
                        passage.length != 0
                            ? <Button
                                color="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showCallRecording(passage)
                                }}>
                                See text
                            </Button>
                            : null
                    }
                </div>
            }
        },

        {
            dataField: "duration",
            text: "Duration",
            formatter: (cell) => {
                return <div>
                    {
                        window.getHMS(Number(cell))
                    }
                </div>
            }
        },
        {
            dataField: "creditsUsed",
            text: "Credits Used",
            formatter: (cell) => {
                return <div>
                    {
                        // moment(cell).format('llll')
                        cell
                    }
                    min
                </div>
            }

        },
        {
            dataField: "price",
            text: "Price (USD)"
        },
        {
            dataField: "start",
            text: "Started At",
            formatter: (cell) => {
                return <div>
                    {
                        moment(cell).format('llll')
                        // cell
                    }
                </div>
            }
        }

    ]);


    useEffect(() => {
        if (virtualNumber) {
            fetchClient()
            fetchCallLogs()
        }
    }, [])



    useEffect(() => {

        if (moment(endDate).isSameOrAfter(moment(startDate))) {
            fetchCallLogs()
            // console.log("check endDate  date ", endDate);
        } else {
            setEndDate(moment().toDate())
            // toast.error("Please select valid date")
        }
    }, [endDate, startDate])



    const fetchClient = () => {
        setLoading(true)
        CP.get({ 'virtualNumber': virtualNumber })
            .then(resp => {
                console.log("[Devlogs] client details", resp);
                setClient(resp)

            }).finally(() => setLoading(false))
    }

    const fetchCallLogs = (page = 1) => {
        setCallLogLoading(true)
        let params = {
            virtualNumber: virtualNumber,
            numero: page,
            start: moment(startDate).format('YYYY-MM-DD') + " 00:00:00:000", // + " 23:59:59:999",
            // new Date('2022-02-02 23:59:59:999')
            end: moment(endDate).format('YYYY-MM-DD') + " 23:59:59:999",

        }
        if (page === 'NaN') {
            return
        }

        CP.getCallLog(params)
            .then(resp => {
                console.log("[Devlogs] call log response", resp);
                setTotalSize(resp.total)
                setPage(resp.current)
                setSizePerPage(10)
                setList([])
                setList(resp.records)
            }).finally(() => setCallLogLoading(false))
    }

    const blockClient = () => {
        let cl = { ...client }
        cl.blocked = !cl.blocked
        CP.patch(cl)
            .then(resp => {
                setClient(cl)
                toast.success(`Client ${cl.blocked ? "Blocked" : "Activated"} successfully`)
            })
    }

    const showCallRecording = (str) => {
        MySwal.fire({
            html: <CallRecords passage={str} />,
            showConfirmButton: false,
            width: '60%',
            // width: isMobile ? '100%' : '60%',
            background: "transparent",
            customClass: {
                content: "text-left"
            }
        })
    }

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        setPage(page)
        setSizePerPage(sizePerPage)
        fetchCallLogs(page);
        // fetchCallLogs();
    }

    const handleDates = (e) => {
        setIsCustomDate(false)

        switch (e.target.value) {
            case 'custom_range':
                setIsCustomDate(true)
                break;
            case 'today':
                setStartDate(moment().toDate())
                setEndDate(moment().toDate())
                break;
            case 'yesterday':
                setStartDate(moment().subtract(1, 'days').toDate())
                setEndDate(moment().subtract(1, 'days').toDate())
                break;
            case 'this_month':
                setStartDate(moment().clone().startOf('month').toDate())
                setEndDate(moment().toDate())
                break;
            case 'last_month':
                setStartDate(moment().subtract(1, 'M').startOf('month').toDate())
                setEndDate(moment().subtract(1, 'M').endOf('month').toDate())
                break;

            case 'this_year':
                setStartDate(moment().clone().startOf('year').toDate())
                setEndDate(moment().toDate())
                break;

            case 'last_year':
                setStartDate(moment().subtract(1, 'Y').startOf('year').toDate())
                setEndDate(moment().subtract(1, 'Y').endOf('year').toDate())
                break;

            default:
                break;
        }
    }


    return (
        <Row className='my-3'>
            <Col>
                <Row>
                    <Col md={4}>
                        <Card>
                            <CardHeader>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <h4>
                                        <FontAwesomeIcon
                                            className='cursor-pointer'
                                            icon={"arrow-left"}
                                            onClick={() => history("/client")} /> {" "}
                                        Client Details
                                    </h4>
                                    <Button
                                        onClick={blockClient}
                                        size={"sm"}
                                        className={"float-right d-none d-md-block"}
                                        color={client.blocked ? "success" : "danger"}>
                                        {client.blocked ? "Activate" : "Block"}
                                    </Button>
                                    <Button
                                        onClick={blockClient}
                                        size={"sm"}
                                        className={"float-right d-block d-sm-none"}
                                        color={client.blocked ? "success" : "danger"}>
                                        {client.blocked ? "Activate" : "Block"}
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody className={"text-left"}>
                                {
                                    loading
                                        ? <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
                                        : <Row>
                                            <Col md={2}>
                                                <FontAwesomeIcon icon="user" size="5x" />
                                            </Col>
                                            <Col sm={12} md={10}>
                                                <Table striped responsive>
                                                    <tbody>
                                                        <tr>
                                                            <th>Name</th>
                                                            <td>{client.userName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td>
                                                                {
                                                                    client.email != undefined
                                                                        ? client.email.split(",").join("\n")
                                                                        : ""
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>Twilio Number</th>
                                                            <td>{client.virtualNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>SMS Number</th>
                                                            <td>{client.realNumber}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th>Time Limit</th>
                                                            <td>{window.getHMS(client.timeLimit * 60)}</td>
                                                        </tr> */}

                                                        <tr>
                                                            <th>Time Spent</th>
                                                            <td>{window.getHMS(client.timeSpent * 60)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Available Credits</th>
                                                            <td>{(client.timeLimit - client.timeSpent)} min</td>
                                                            {/* <td>{window.getHMS((client.timeLimit - client.timeSpent) * 60)}</td> */}
                                                        </tr>

                                                        <tr>
                                                            <th>Transcript Service</th>
                                                            <td>
                                                                <Badge
                                                                    color={
                                                                        client.scriptService
                                                                            ? "success"
                                                                            : "danger"
                                                                    }>
                                                                    {
                                                                        client.scriptService
                                                                            ? "Enabled"
                                                                            : "Disabled"
                                                                    }
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Status</th>
                                                            <td>
                                                                <Badge
                                                                    color={
                                                                        client.blocked
                                                                            ? "danger"
                                                                            : "success"
                                                                    }>
                                                                    {
                                                                        client.blocked
                                                                            ? "Blocked"
                                                                            : "Active"
                                                                    }
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                {
                                                                    moment.utc(client.expDate).local().isAfter()
                                                                        ? "Expired On"
                                                                        : "Expired At"
                                                                }
                                                            </th>
                                                            <td>
                                                                <Badge
                                                                    color={
                                                                        moment.utc(client.expDate).local().isAfter()
                                                                            ? "success"
                                                                            : "danger"
                                                                    }>

                                                                    {
                                                                        moment.utc(client.expDate).local().format(window.format)//.format('llll')
                                                                    }
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={8}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={3}>
                                        <h4>Call Logs</h4>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col sm={12} lg={4}>
                                                <Input type={"select"} onChange={handleDates} size={"sm"}>
                                                    <option value={"custom_range"}>Custom Range</option>
                                                    <option value={"today"}>Today</option>
                                                    <option value={"yesterday"}>Yesterday</option>
                                                    <option value={"this_month"}>This Month</option>
                                                    <option value={"last_month"}>Last Month</option>
                                                    <option value={"this_year"}>This Year</option>
                                                    <option value={"last_year"}>Last Year</option>
                                                </Input>
                                            </Col>
                                            <Col sm={12} lg={4} className={"d-flex justify-content-between"}>
                                                <Row className={'align-items-center'}>
                                                    <Col md={3}>From</Col>
                                                    <Col>
                                                        <RangeDropdown
                                                            startDate={startDate}
                                                            setStartDate={isCustomDate ? setStartDate : null}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col sm={12} lg={4} className={"d-flex justify-content-between align-items-center"}>
                                                <Row className={'align-items-center'}>
                                                    <Col md={3}>To</Col>
                                                    <Col>
                                                        <RangeDropdown
                                                            startDate={endDate}
                                                            setStartDate={isCustomDate ? setEndDate : null}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody className={"text-left"}>
                                {
                                    callLogLoading
                                        ? <FontAwesomeIcon icon={'spinner'} transform="left-5 down-1" spin />
                                        : <TableBody
                                            list={list}
                                            rowId={"callId"}
                                            page={page}
                                            sizePerPage={sizePerPage}
                                            totalSize={totalSize}
                                            handleTableChange={handleTableChange}
                                            columns={columns}
                                            message={"There are no logs yet!"}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
